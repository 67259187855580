// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const pageAlertSlice = createSlice({
  name: 'pageAlert',
  initialState: {
    message: "",
    show: false,
    title: "",
    type: "",
    canClose: true
  },
  reducers: {
    handlePageAlertDismiss: (state) => {
      state.show = false
    },
    handlePageAlertInit: (state, action) => {
      state.message = action.payload.message
      state.show = true
      state.title = action.payload.title
      state.type = action.payload.type
      if (action.payload.canClose !== undefined) {
        state.canClose = action.payload.canClose
      } else {
        state.canClose = true
      }
    }
  }
})

export const {
  handlePageAlertDismiss,
  handlePageAlertInit
} = pageAlertSlice.actions

export default pageAlertSlice.reducer
