// ** Reducers Imports
import account from './account'
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import pageAlert from './pageAlert'

const rootReducer = {
  account,
  auth,
  navbar,
  layout,
  pageAlert
}

export default rootReducer
