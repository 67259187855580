import useJwt from '@src/auth/jwt/useJwt'
import jwtDefaultConfig from './jwtDefaultConfig'
import axios from 'axios'
import Cookies from 'js-cookie'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        return config
      },
      error => Promise.reject(error)
    )

    axios.interceptors.response.use(
      response => {
        return response
      },
      async error => {
        const originalRequest = error.config
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true
          try {
            const response = await this.refreshToken()
            const accessToken = response.data.jwt
            this.onAccessTokenFetched(accessToken)
            return await axios(originalRequest, { withCredentials: true })
          } catch {
            let url = 'wetu.com'
            const subDomain = process.env.REACT_APP_SUB_DOMAIN
            if (subDomain) {
              url = `${subDomain}.${url}`
            }
            Cookies.remove(useJwt.jwtConfig.identifierTokenCookieName, { path: '/', domain: url })
            Cookies.remove(useJwt.jwtConfig.identifierTokenCookieName, { path: '/', domain: `.${url}` })
            const location = window.self.location
            let redirectUrl = `/Login`
            if (location && (location.ancestorOrigins || location.href)) {
              const isIframe = window.self !== window.top
              const dashboardBaseUrl = isIframe && location.ancestorOrigins && location.ancestorOrigins[0] ? location.ancestorOrigins[0] : null
              redirectUrl = `/Login?ReturnUrl=${dashboardBaseUrl ? `${dashboardBaseUrl}/${location.pathname}` : location.href}`
            }
            window.top.location.href = redirectUrl
          }
        }

        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  async refreshToken() {
    return await axios.post(this.jwtConfig.refreshEndpoint, {
      accessToken: "",
      refreshTokenId: "",
      isAccessTokenCookiePersistant: false,
      isRefreshTokenCookiePersistant: false
    }, { withCredentials: true })
  }
}
