import { createSlice } from '@reduxjs/toolkit'
import { getUserData } from '@src/auth/utils'

const initialUser = () => {
  return { ...getUserData() }
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
    }
  }
})

export const { 
  handleLogin
} = authSlice.actions

export default authSlice.reducer
