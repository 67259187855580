// ** Initial user ability
export const initialAbility = [
  {
    action: 'read',
    subject: 'login'
  },
  {
    action: 'read',
    subject: 'register'
  },
  {
    action: 'read',
    subject: 'updatePassword'
  },
  {
    action: 'read',
    subject: 'verifying'
  }
]

export const _ = undefined
