import JwtService from './jwtWetuService'
import jwtWetuConfig from './jwtWetuConfig'

function useJwt(jwtOverrideConfig) {
    const jwt = new JwtService(jwtOverrideConfig)
  
    return {
      jwt
    }
  }

const { jwt } = useJwt(jwtWetuConfig)

export default jwt
