import { Ability } from '@casl/ability'
import { getUserData, isUserLoggedIn } from '@src/auth/utils'
import { initialAbility } from './initialAbility'

let userData = null 

// * Handles auto fetching previous abilities if already logged in user
if (isUserLoggedIn()) {
    userData = getUserData()

    if (!userData.DemographicStepCompleted && userData.UserRole === 'Admin') {
        userData.ability = [...initialAbility, { action: 'read', subject: 'updateAccount' }]
    }
}

const existingAbility = userData ? userData.ability : null

export default new Ability(existingAbility || initialAbility)
