import useJwt from '@src/auth/jwt/useJwt'
import Cookies from 'js-cookie'
import jwt_decode from "jwt-decode"

export const isUserLoggedIn = () => {
  return !!Cookies.get(useJwt.jwtConfig.identifierTokenCookieName)
}

export const getUserData = () => {
  const cookie = Cookies.get(useJwt.jwtConfig.identifierTokenCookieName)

  if (cookie) {
    return jwt_decode(cookie)
  }

  return {}
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}
