// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getCountryCode = createAsyncThunk('account/getCountryCode', async () => {
  const response = await axios.get('https://ipinfo.io/json?token=a4caeb5dee960f')
  
  return response.data.country ? response.data.country.toLowerCase() : 'za'
})

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    companyName: '',
    country: '',
    countryCode: '',
    name: '',
    phoneNumber: '',
    surname: '',
    type: '',
    website: ''
  },
  reducers: {
    handleDemographics: (state, action) => {
      state.companyName = action.payload.companyName
      state.country = action.payload.country
      state.countryCode = action.payload.countryCode
      state.name = action.payload.name
      state.phoneNumber = action.payload.phoneNumber
      state.surname = action.payload.surname
      state.website = action.payload.website
    },
    handleType: (state, action) => {
      state.type = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getCountryCode.fulfilled, (state, action) => {
        state.countryCode = action.payload
      })
  }
})

export const { 
  handleType,
  handleDemographics
 } = accountSlice.actions

export default accountSlice.reducer
